import moment from "moment";
import { React, Component } from "react";
import { toast } from "react-toastify";
import HeadingCompnonet from "./HeadingComponent";
import history from "../../../history";
import "./needHelpLists.css";
const MaterialService = require("../../../services/MaterialService");

class NeedHelpRequestsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            NeedHelpRequestsList: [],
            filteredList: [],
            filter: "",
            showDropdown: false,
        };
    }

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/requests/list").then((res) => {
            if (res.status) {
                this.setState({
                    NeedHelpRequestsList: res.data,
                    filteredList: res.data,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    onInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedList = [...this.state.NeedHelpRequestsList];
        updatedList[index][name] = value;

        this.setState({ NeedHelpRequestsList: updatedList }, () => {
            this.updateStatus(index);
        });
    };

    handleFilterClick = () => {
        this.setState((prevState) => ({
            showDropdown: !prevState.showDropdown,
        }));
    };

    handleFilterChange = (filter) => {
        this.setState({ filter, showDropdown: false }, () =>
            this.applyFilter()
        );
    };

    applyFilter = () => {
        const { NeedHelpRequestsList, filter } = this.state;

        const filteredList = filter
            ? NeedHelpRequestsList.filter((item) => item.status === filter)
            : NeedHelpRequestsList;

        this.setState({ filteredList });
    };

    render() {
        const { showDropdown } = this.state;

        return (
            <div id="needHelplists" className="need-help-list-main-container">
                <HeadingCompnonet
                    mainTitle="Need Help"
                    title="View Banners"
                    path="/material-manager/need-help-lists"
                    titleTwo="create need help"
                    pathTwo="/material-manager/need-help"
                />
                <div className="main-container">
                    <div className="main-need-help-heading">
                        Need help- Banner Listing Page
                    </div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading">
                            Submitted On
                        </div>
                        <div
                            className="col-md-5 no-padding row-heading"
                            style={{ marginLeft: "70px" }}
                        >
                            Need help on
                        </div>

                        <div
                            className="col-md-2 no-padding row-heading cursor-pointer position-relative"
                            onClick={this.handleFilterClick}
                        >
                            Status
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginLeft: "10px" }}
                            ></i>
                            {showDropdown && (
                                <div className="filter-dropdown">
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange("")
                                        }
                                    >
                                        All
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "Relocating"
                                            )
                                        }
                                    >
                                        Relocating
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "SeniorCitizen"
                                            )
                                        }
                                    >
                                        Senior Citizen
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.handleFilterChange(
                                                "House Declutter"
                                            )
                                        }
                                    >
                                        House Declutter
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className="col-md-1 no-padding row-heading"
                            style={{ marginLeft: "30px" }}
                        >
                            Edit
                        </div>
                    </div>

                    <div className="col-md-12 row no-padding">
                        {this.state.filteredList.length > 0 ? (
                            this.state.filteredList.map((list, index) => (
                                <div
                                    key={index}
                                    className="col-md-12 row no-padding margin-top need-help-details-contents"
                                >
                                    <div className="col-md-2 no-padding">
                                        {moment(list.requestedDate).format(
                                            "DD-MMM-YYYY"
                                        )}
                                    </div>
                                    <div
                                        className="col-md-5"
                                        style={{ marginLeft: "60px" }}
                                    >
                                        <div>
                                            Title: Are you cleaning up your home
                                        </div>
                                        <img
                                            src="/images/Rectangle.png"
                                            className="col-md-4 no-padding need-list-image"
                                            alt="Help"
                                        />
                                    </div>
                                    <select
                                        className="col-md-2 select-droopdown padding-no"
                                        name="status"
                                        value={list.status}
                                        onChange={(event) =>
                                            this.onInputChange(index, event)
                                        }
                                    >
                                        <option value="Relocating">
                                            Relocating
                                        </option>
                                        <option value="SeniorCitizen">
                                            Senior Citizen
                                        </option>
                                        <option value="House Declutter">
                                            House Declutter
                                        </option>
                                    </select>
                                    <div className="col-md-1 banner-edit-button">
                                        <i
                                            className="fas fa-pencil-alt"
                                            aria-hidden="true"
                                            style={{
                                                color: "#DA4C76",
                                                cursor: "pointer",
                                            }}
                                            // onClick={() =>
                                            //     history.push(
                                            //         `/edit-banner/${lists.id}`
                                            //     )
                                            // }
                                        ></i>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div
                                className="alert alert-warning"
                                style={{
                                    width: "max-content",
                                    height: "50px",
                                    textAlign: "center",
                                    margin: "auto",
                                }}
                            >
                                No Data Found
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default NeedHelpRequestsList;
