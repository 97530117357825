import React, { Component } from "react";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./materialRequestsLists.css";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
const MaterialService = require("../../../services/MaterialService");

class MaterialsRequestLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            materialRequestsLists: [],
            filteredRequests: [],
            selectedStatus: "all",
            showStatusOptions: false,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/material-requests/list").then(
            (res) => {
                if (res.status) {
                    this.setState({
                        materialRequestsLists: res.data,
                        filteredRequests: res.data,
                        downloadKey: res.meta.downloadKey,
                        loaded: true,
                    });
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        );
    };

    handleStatusFilter = (status) => {
        const filteredRequests =
            status === "all"
                ? this.state.materialRequestsLists
                : this.state.materialRequestsLists.filter(
                      (request) => request.status === parseInt(status)
                  );

        this.setState({
            selectedStatus: status,
            filteredRequests,
            showStatusOptions: false,
        });
    };

    toggleStatusOptions = () => {
        this.setState((prevState) => ({
            showStatusOptions: !prevState.showStatusOptions,
        }));
    };

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/material-requests/status/" + id, {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };

    handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(
            this.state.filteredRequests.map((request) => ({
                RequestedOn: moment(request.createdAt).format("DD MMM YYYY"),
                FullName: request.miscInfo.primary_name || "",
                Email: request.miscInfo.email || "",
                Mobile: request.miscInfo.mobile_no || "",
                OrgName: request.miscInfo.org_name || "",
                Status: this.getStatusText(request.status),
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Material Requests");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "material_requests.xlsx"
        );
    };

    getStatusText = (status) => {
        switch (status) {
            case 0:
                return "Requested";
            case 1:
                return "Approved";
            case 2:
                return "Requested Canceled";
            default:
                return "";
        }
    };
    handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(
            this.state.filteredRequests.map((request) => ({
                RequestedOn: moment(request.createdAt).format("DD MMM YYYY"),
                FullName: request.miscInfo.primary_name || "",
                Email: request.miscInfo.email || "",
                Mobile: request.miscInfo.mobile_no || "",
                OrgName: request.miscInfo.org_name || "",
                Status: this.getStatusText(request.status),
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Material Requests");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "material_requests.xlsx"
        );
    };

    getStatusText = (status) => {
        switch (status) {
            case 0:
                return "Requested";
            case 1:
                return "Approved";
            case 2:
                return "Requested Canceled";
            default:
                return "";
        }
    };
    render() {
        return (
            <div className="main-container" id="materailsRequestsLists">
                <div>
                    <HeadingCompnonet mainTitle="Material Requests" />

                    <div className="banners-buttons">
                        <div
                            className="save-button"
                            onClick={this.handleDownload}
                        >
                            Download
                        </div>
                    </div>

                    <div className="materails-requests-main-container">
                        <div className="col-md-12 row heading-container ">
                            <div className="col-md-1 padding-no">
                                Requested on
                            </div>
                            <div className="col-md-2 padding-no">Full Name</div>
                            <div className="col-md-2 padding-no">Email</div>
                            <div className="col-md-1 padding-no">Mobile</div>
                            <div className="col-md-3 padding-no organistaion-name">
                                ORG Name
                            </div>
                            <div className="status-container col-md-2 padding-no">
                                <div className="status-label">Status</div>
                                <div
                                    className="status-dropdown"
                                    onClick={this.toggleStatusOptions}
                                >
                                    <i
                                        className={`fas fa-chevron-down dropdown-icon ${
                                            this.state.showStatusOptions
                                                ? "rotate"
                                                : ""
                                        }`}
                                    ></i>
                                </div>
                                {this.state.showStatusOptions && (
                                    <ul className="status-options">
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("all");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            All
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("0");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Requested
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("1");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Approved
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("2");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Requested Canceled
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div
                                style={{ marginLeft: "30px" }}
                                className="col-md-1 padding-no"
                            >
                                Edit
                            </div>
                        </div>
                    </div>

                    <div className="materials-requests-lists-container">
                        {this.state.loaded ? (
                            this.state.filteredRequests.map((details) => (
                                <div
                                    className="materials-requests-lists-contents col-md-12 row"
                                    key={`materialre${details.id}`}
                                >
                                    <div className="col-md-1 padding-no">
                                        {moment(details.createdAt).format(
                                            "DD MMM YYYY"
                                        )}
                                    </div>
                                    <div className="col-md-2 padding-no">
                                        {details.miscInfo.primary_name || ""}
                                    </div>
                                    <div className="col-md-2 padding-no">
                                        <a
                                            href={`mailto:${
                                                details.miscInfo.email || ""
                                            }`}
                                        >
                                            {details.miscInfo.email || ""}
                                        </a>
                                    </div>
                                    <div className="col-md-1 padding-no">
                                        <a
                                            href={`tel:${
                                                details.miscInfo.mobile_no || ""
                                            }`}
                                        >
                                            {details.miscInfo.mobile_no || ""}
                                        </a>
                                    </div>
                                    <div className="col-md-3 padding-no">
                                        {details.miscInfo.org_name || ""}
                                    </div>
                                    <select
                                        className="col-md-2 select-dropdown padding-no"
                                        onChange={(ev) => {
                                            this.updateStatus(
                                                details.id,
                                                ev.target.value,
                                                this.fetchData
                                            );
                                        }}
                                        value={details.status || 0}
                                    >
                                        <option value={0}>Requested</option>
                                        <option value={2}>
                                            Requested Canceled
                                        </option>
                                        <option value={1}>Approved</option>
                                    </select>
                                    <div className="col-md-1 banner-edit-button padding-no">
                                        <i
                                            className="fas fa-pencil-alt"
                                            onClick={() => {
                                                history.push(
                                                    "/material-manager/material-requests-details/" +
                                                        details.id
                                                );
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <LoaderSpinner />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default MaterialsRequestLists;
