import React, { Component } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import history from "../../../history";
import "./dropOffLocationsList.css";
import HeadingComponent from "./HeadingComponent";
const MaterialService = require("../../../services/MaterialService");

class DropOffLocationsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropOffDetails: [],
            filteredRequests: [],
            selectedStatus: "all",
            showStatusOptions: false,
            downloadKey: null,
            statusDropdownOpen: {},
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.fetchData();
        }
    }

    fetchData = () => {
        MaterialService.fetchData("/v2/manager/drop-off/list").then((res) => {
            if (res.status) {
                this.setState({
                    dropOffDetails: res.data,
                    filteredRequests: res.data,
                    downloadKey: res.meta.downloadKey,
                    loaded: true,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };

    handleStatusFilter = (status) => {
        const filteredRequests =
            status === "all"
                ? this.state.dropOffDetails
                : this.state.dropOffDetails.filter(
                      (request) => request.status.toString() === status
                  );

        this.setState({
            selectedStatus: status,
            filteredRequests,
            showStatusOptions: false,
        });
    };

    toggleStatusOptions = () => {
        this.setState((prevState) => ({
            showStatusOptions: !prevState.showStatusOptions,
        }));
    };

    toggleIndividualStatusDropdown = (id) => {
        this.setState((prevState) => ({
            statusDropdownOpen: {
                ...prevState.statusDropdownOpen,
                [id]: !prevState.statusDropdownOpen[id],
            },
        }));
    };

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/drop-off/status-update", {
            id,
            status,
        }).then((res) => {
            callback();
        });
    };

    getStatusText = (status) => {
        switch (status) {
            case 1:
                return "Active";
            case 0:
                return "Inactive";
            case 2:
                return "Requested";
            default:
                return "";
        }
    };

    handleEditClick = (id) => {
        history.push(`/material-manager/drop-off-update/${id}`);
    };

    handleDownload = () => {
        const ws = XLSX.utils.json_to_sheet(
            this.state.dropOffDetails.map((request) => ({
                RequestedOn: moment(request.createdDate).format("DD MMM YYYY"),
                FullName: request.fullName || "",
                Email: request.email || "",
                Mobile: request.phone || "",
                Availability: request.availability || "Not Specified",
                Status: this.getStatusText(request.status),
            }))
        );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Drop-Off Locations");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "drop_off_locations.xlsx"
        );
    };

    render() {
        return (
            <div className="main-container" id="dropOfLocationLists">
                <div>
                    <HeadingComponent
                        mainTitle="Drop-off Locations"
                        title="ADD Dropoff Location"
                        path="/material-manager/drop-off-setup"
                    />
                    <div className="banners-buttons">
                        <div
                            className="save-button"
                            style={{ cursor: "pointer" }}
                            onClick={this.handleDownload}
                        >
                            Download
                        </div>
                    </div>
                    <div className="main-drop-off-container">
                        <div className="main-drop-off-heading">
                            List of Drop-off Locations
                        </div>
                        <div className="col-md-12 row heading-container padding-no">
                            <div className="col-md-1 padding-no">
                                Created on
                            </div>
                            <div className="col-md-2 padding-no">Full Name</div>
                            <div className="col-md-3 padding-no">Email</div>
                            <div
                                className="col-md-2 padding-no"
                                style={{ marginLeft: "-20px" }}
                            >
                                Mobile
                            </div>
                            <div
                                className="col-md-1 padding-no"
                                style={{ marginLeft: "-30px" }}
                            >
                                Availability
                            </div>
                            <div className="status-container col-md-2 padding-no">
                                <div
                                    className="status-label"
                                    style={{ marginLeft: "42px" }}
                                >
                                    Status
                                </div>
                                <div
                                    className="status-dropdown"
                                    onClick={this.toggleStatusOptions}
                                >
                                    <i
                                        className={`fas fa-chevron-down dropdown-icon ${
                                            this.state.showStatusOptions
                                                ? "rotate"
                                                : ""
                                        }`}
                                    ></i>
                                </div>
                                {this.state.showStatusOptions && (
                                    <ul className="status-options">
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("all");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            All
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("1");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Active
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("0");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Inactive
                                        </li>
                                        <li
                                            onClick={() => {
                                                this.handleStatusFilter("2");
                                                this.toggleStatusOptions();
                                            }}
                                        >
                                            Requested
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div className="col-md-1 padding-no">Edit</div>
                        </div>
                        <div className="volunteer-details-container">
                            {this.state.filteredRequests.length > 0 ? (
                                this.state.filteredRequests.map((lists) => (
                                    <div
                                        key={lists.id}
                                        className="drop-off-details-contents col-md-12 row padding-no"
                                    >
                                        <div className="col-md-1 padding-no">
                                            {moment(lists.createdDate).format(
                                                "DD-MMM-YYYY"
                                            )}
                                        </div>
                                        <div className="col-md-2 padding-no">
                                            {lists.fullName}
                                        </div>
                                        <div className="col-md-3 padding-no">
                                            {lists.email}
                                        </div>
                                        <div
                                            className="col-md-2 padding-no "
                                            style={{ marginLeft: "-20px" }}
                                        >
                                            {lists.phone}
                                        </div>
                                        <div
                                            className="col-md-1 padding-no"
                                            style={{ marginLeft: "-30px" }}
                                        >
                                            {lists.availability
                                                ? lists.availability.trim() ===
                                                  ""
                                                    ? "Not Specified"
                                                    : lists.availability
                                                : "Not Specified"}
                                        </div>
                                        <div className="col-md-2 status-dropdown-container">
                                            <div
                                                style={{ cursor: "pointer" }}
                                                className="status-dropdown-trigger"
                                                onClick={() =>
                                                    this.toggleIndividualStatusDropdown(
                                                        lists.id
                                                    )
                                                }
                                            >
                                                {this.getStatusText(
                                                    lists.status
                                                )}
                                                <i
                                                    className={`fas fa-chevron-down dropdown-icon ${
                                                        this.state
                                                            .statusDropdownOpen[
                                                            lists.id
                                                        ]
                                                            ? "rotate"
                                                            : ""
                                                    }`}
                                                ></i>
                                            </div>
                                            {this.state.statusDropdownOpen[
                                                lists.id
                                            ] && (
                                                <ul className="status-options">
                                                    <li
                                                        onClick={() => {
                                                            this.updateStatus(
                                                                lists.id,
                                                                "1",
                                                                this.fetchData
                                                            );
                                                            this.toggleIndividualStatusDropdown(
                                                                lists.id
                                                            );
                                                        }}
                                                    >
                                                        Active
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.updateStatus(
                                                                lists.id,
                                                                "0",
                                                                this.fetchData
                                                            );
                                                            this.toggleIndividualStatusDropdown(
                                                                lists.id
                                                            );
                                                        }}
                                                    >
                                                        Inactive
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.updateStatus(
                                                                lists.id,
                                                                "2",
                                                                this.fetchData
                                                            );
                                                            this.toggleIndividualStatusDropdown(
                                                                lists.id
                                                            );
                                                        }}
                                                    >
                                                        Requested
                                                    </li>
                                                </ul>
                                            )}
                                        </div>
                                        <div
                                            className="col-md-1 banner-edit-button padding-no"
                                            style={{ marginLeft: "-60px" }}
                                        >
                                            <i
                                                className="fa fa-edit fa-lg"
                                                onClick={() =>
                                                    this.handleEditClick(
                                                        lists.id
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center mt-3">
                                    No data available
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DropOffLocationsList;
